body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato';
}

a {
  text-decoration: none !important;
}

.sb-common-content__carousel-destaque-3-items .owl-theme .owl-dots .owl-dot span {
  background-color: #fff !important;
  width: 12px !important;
  height: 12px !important;
  border: 1px solid #d6d6d6;
  box-shadow: 0px 0px 0px 1px #d6d6d6;
  display: flex;
  align-items: stretch;
}

.sb-common-content__carousel-destaque-3-items .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background-color: #2e66b7 !important;
  border: 1.5px solid #f6f6f6;
}

.sb-common-content__carousel-destaque-3-items .owl-theme .owl-nav {
  visibility: hidden;
}

.sb-common-content__carousel-destaque-3-items .owl-theme:hover .owl-nav {
  visibility: visible;
}

.sb-common-content__carousel-destaque-3-items .owl-theme .owl-nav .owl-prev {
  font-size: 25px !important;
  display: none;
  outline: 0;
  position: absolute;
  top: calc(50% - 26px);
  width: 40px;
  height: 40px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2);
  background: #2e66b7 !important;
  color: #fff !important;
  opacity: 1;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  left: -35px;
}

.sb-common-content__carousel-destaque-3-items .owl-theme .owl-nav .owl-next {
  font-size: 25px !important;
  display: none;
  padding: 0;
  outline: 0;
  position: absolute;
  top: calc(50% - 26px);
  width: 40px;
  height: 40px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2);
  background: #2e66b7 !important;
  color: #fff !important;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  right: -30px;
}

.sb-common-content__carousel-destaque-3-items .owl-theme:hover .owl-nav .owl-prev, .owl-theme:hover .owl-nav .owl-next {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sb-common-content__carousel-destaque-3-items .owl-theme .owl-nav .owl-prev span, .owl-theme .owl-nav .owl-next span {
  margin: 0;
  padding: 0;
}

.sb-common-content__carousel-destaque-3-items .owl-theme .owl-nav .owl-prev.disabled, .owl-theme .owl-nav .owl-next.disabled {
  visibility: hidden;
}

#carousel-mensagens .owl-theme .owl-nav .owl-next {
  background-color: inherit !important;
  display: block;
  padding: 0;
  outline: 0;
  position: absolute;
  top: calc(50% - 26px);
  width: 40px;
  height: auto;
  border-radius: 5px;
  box-shadow: none;
  color: #fff !important;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  right: 0px;
}

#carousel-mensagens .owl-theme .owl-nav .owl-prev {
  background-color: inherit !important;
  display: block;
  padding: 0;
  outline: 0;
  position: absolute;
  top: calc(50% - 26px);
  width: 40px;
  height: auto;
  border-radius: 5px;
  box-shadow: none;
  color: #fff !important;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  left: 0px;
}

#carousel-mensagens .owl-theme .owl-dots .owl-dot span {
  background-color: inherit !important;
  width: 12px !important;
  height: 12px !important;
  border: 2px solid #fff;
  box-shadow: none;
  display: flex;
  align-items: stretch;
}

#carousel-mensagens .owl-theme .owl-dots .owl-dot.active span {
  background-color: #fff !important;
  width: 12px !important;
  height: 12px !important;
  border: 2px solid #fff;
  box-shadow: none;
  display: flex;
  align-items: stretch;
}
