.sb-notfound {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.container-manutencao {
  top: calc(50% - 93px);
  position: absolute !important;
  left: calc(50% - 360px);
}

@media (max-width: 767px) {
  .logo-manutencao {
    width: 100%;
    float: none;
    margin-bottom: 30px;
  }

  .container-manutencao {
    left: 0;
    width: 100% !important;
    top: calc(50% - 163px);
  }

  .sb-notfound {
    padding: 0 20px;
    text-align: center;
  }

  .box-descricao-manutencao {
    float: none !important;
  }
}

